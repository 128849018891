'use strict';

dashboardController.$inject = [
  '$scope',
  '$sessionStorage',
  '$uibModal',
  'News',
  'Instances'
];
function dashboardController($scope, $sessionStorage, $uibModal, News, Instances) {

  $scope.noNews = true;
  News.listAvailableNews().then(
    function onSuccess(result){
      if (angular.isArray(result)) {
        $scope.newsList = result;
        if ( result.length > 0 ) {
          $scope.noNews = false;
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    },
    function onError(result) {
      $scope.messages.status = 'danger';
      $scope.messages.message = result;
    }
  );

  $scope.isLoading = true;
  $scope.noInstances = true;
  Instances.listInstances().then(
    function(result){
      $scope.isLoading = false;
      if (angular.isArray(result)) {
        var instances = [];
        if ( result.length > 0 ) {
          angular.forEach(result, function(instance, i){
            if ( instance.instanceStatus == 1 ) {
              instances.push(instance);
            }
          });
          if ( instances.length > 0) {
            $scope.noInstances = false;
            $scope.instanceList = instances;
          }
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    },
    function (result) {
      $scope.isLoading = false;
      $scope.messages.status = 'danger';
      $scope.messages.message = result;
    }
  );

  var _openNews = function(id) {
    News.getNews(id).then(function(data){
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/news_detail.html",
        backdrop: "static",
        scope: $scope,
        controller : function($uibModalInstance ,$scope){
          $scope.news = data;
          $scope.content = $scope.news.newsContents;
          $scope.close = function() {
            $uibModalInstance.close();
          };
        }
      });
    })
  };

  $scope.openNews = _openNews;

};

module.exports = dashboardController;
