'use strict';

instanceController.$inject = [
  '$scope',
  '$routeParams',
  '$uibModal',
  '$q',
  'Instances',
]

function instanceController($scope, $routeParams, $uibModal, $q, Instances) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Open edit field for instance comment
  var _addComment = function() {
    $scope.previousComment = $scope.instance.instanceComment;
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Close edit field for instance comment
  var _cancelComment = function() {
    $scope.instance.instanceComment = $scope.previousComment;
    $scope.editMode = false;
  }

  //////////////////////////////////////////////////////////////////////
  // Save instance comment
  var _saveComment = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var param = {
      comment: $scope.instance.instanceComment
    }

    Instances.addComment($routeParams.id, param).then(function(data){
      $scope.editMode = false;
      modal.close();
      if (data.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = "コメントを保存しました。";
      }
      else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Save instance comment
  var _openResetPassword = function(type) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/change_ftp_password.html",
      backdrop: "static",
      scope: $scope,
      controller: 'changeFtpPasswordModalController',
      resolve: {
        params: function() {
          var param = {
            instanceId: $scope.instance.instanceId,
            instanceHostname: $scope.instance.instanceHostname
          };
          if ( type == 'admin') {
            param.user = $scope.instance.instanceFtpAdminAccount
          }
          else {
            param.user = $scope.instance.instanceFtpUserAccount
          }

          return param;
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( angular.isObject(data) ) {
          $scope.instance = data;
          $scope.messages.status = 'success';
          $scope.messages.message = "FTP パスワードを変更しました。";
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = "FTP パスワードを変更できませんでした。";
        }
    },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open network bandwidth list
  var _openNetworkData = function(type) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/instance_band_width.html",
      backdrop: "static",
      scope: $scope,
      controller: 'instanceBandWidthModalController',
      resolve: {
        params: function() {
          return {
            instanceId: $scope.instance.instanceId,
            instanceHostname: $scope.instance.instanceHostname
          }
        }
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Open network bandwidth list
  var _restartMT = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "Movable Type の再起動";
        $scope.messageBody = "Movable Type を再起動します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Instances.restartMT($routeParams.id).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'Movable Type を再起動しました。';
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };
  var _upgradeMT8 = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "Movable Type 8 へのアップグレード";
        $scope.messageBody = `インスタンス [${$scope.$parent.instance.instanceHostname}] を Movable Type 8 にアップグレードします。よろしいですか？`;
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      return Instances.upgradeMT8( $scope.instance.instanceId).then( function(result) {
      }, function () {} );
    });
  };

  var _rollback = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/rollback.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.choose = function() {
          return Instances.rollback( $scope.$parent.instance.instanceId, $scope.backupDate )
            .then( function() {
              $scope.$parent.canRollback = false;
              $uibModalInstance.close();
            });
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      },
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Functin Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.addComment = _addComment;
  $scope.cancelComment = _cancelComment;
  $scope.saveComment = _saveComment;
  $scope.openResetPassword = _openResetPassword;
  $scope.openNetworkData = _openNetworkData;
  $scope.restartMT = _restartMT;
  $scope.upgradeMT8 = _upgradeMT8;
  $scope.rollback = _rollback;

  //////////////////////////////////////////////////////////////////////
  // Method
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading data
  var _load = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadInstance(),
      _loadDiskUsage(),
      _loadCpuUsage(),
      _loadMemoryUsage(),
      _loadBandwidth(),
      _loadAuthors(),
      _loadSites(),
    ])
    .then(function(){
      _checkAuthorsChanged();
      modal.close();
    })

  };

  //////////////////////////////////////////////////////////////////////
  // Loading instance data
  var _loadInstance = function() {

    return Instances.getInstance($routeParams.id).then(function(data){
      $scope.instance = data;
      if ( data.instanceStatus == 1) {
        $scope.action = {};
        $scope.action.viewServerLog = true;
        $scope.canChangeFtpPassword = true;
        $scope.action.restartMovableType = true;
        $scope.action.upgradeMT = true;
        $scope.action.rollback = true;

        $scope.action.upgradeMT8 = false;
        $scope.action.rollback = false;
        $scope.instance.isRestoreRunning = false;

        if ( data.instanceOs == 'rocky' && data.instanceDetail.instanceDetailProductVersion == 7 ) {
          $scope.action.upgradeMT8 = true;
        }
        if ( data.instanceOs == 'rocky' && data.instanceDetail.instanceDetailProductVersion == 8 ) {
          return Instances.restoreStatus( $routeParams.id ).then( function (r) {
            if ( r.status == 'notrunning' ) {
              $scope.action.rollback = true;
            }
            if ( r.status == 'running' ) {
              $scope.instance.isRestoreRunning = true;
            }
          })
          .then( function (r) {
            if ($scope.action.rollback) {
              return Instances.getBackupFiles( $routeParams.id ).then( function (r) {
                if (r.status == 500) {
                  $scope.action.rollback = false;
                  return;
                }
                const now = new Date(0);
                const today = `${now.getFullYear()}${now.getMonth}${now.getDate}`;
                let backups = [];
                const data = r.sort( (a,b) => ( b.date - a.date ) );
                for ( const b of data ) {
                  if ( b.version.match( /^7/ ) ) {
                    if ( b.date == today ) {
                      backups.push( { label: b.date, value: 'TODAY' } );
                    } else {
                      backups.push( { label: b.date, value: b.date });
                    }
                  }
                }
                $scope.backupFiles = backups;
                if ( backups.length == 0 ) {
                  $scope.action.rollback = false;
                }
              })
              .catch( () => {
                $scope.action.rollback = false;
              });
            }
          });
        }
      }
    });

  };

  //////////////////////////////////////////////////////////////////////
  // Loading instance disk usage data
  var _loadDiskUsage = function() {

    return Instances.getInstanceDiskUsage($routeParams.id).then(function(data){
      $scope.diskMetrics = data;
    });

  };

  //////////////////////////////////////////////////////////////////////
  // Loading network bandwidth
  const _loadBandwidth = function() {
    return Instances.getNetworkBandWidth($routeParams.id, null).then(function(data){
      if ( ! 'instance' in $scope ) {
        return;
      }

      const GiB = 1024 * 1024 * 1024;
      const TiB = GiB * 1024;

      const _is_private = function ( instance ) {
        const seller = (typeof($scope.instance) == 'object' && 'order' in $scope.instance) ? $scope.instance.order.seller : {} ;
        return seller.sellerName == 'プライベート' ? true : false;
      }

      const plan = ( typeof($scope.instance) == 'object' && 'instanceDetail' in $scope.instance ) ? $scope.instance.instanceDetail.instanceDetailPlan : undefined ;
      const freetier = plan ? _freeBandwidthByPlan( plan ) : 0 ;
      $scope.freetier = ( freetier == 0 || _is_private( $scope.instance ) ) ? '' : `転送量枠: ${ Number(( freetier / TiB ).toFixed(1)).toLocaleString('ja') } TiB / ${ Number(( freetier / GiB ).toFixed(0)).toLocaleString('ja') } GiB`;

      if ( data && data.bandwidth && data.bandwidth.length > 0 ) {
        const bw = data.bandwidth[0]
        const raw = bw.raw;
        bw.bandwidth = `${ Number( (raw / GiB).toFixed(2) ).toLocaleString('ja') } GiB`;

        const excess = ( raw > freetier ) ? raw - freetier : 0;
        bw.excess = (freetier > 0 && _is_private( $scope.instance ) == false && excess > 0) ? `今月の転送量枠を ${ Math.ceil( excess / GiB ).toLocaleString('ja')} GiB 超過しています` : "";

        const d = new Date();
        const passed = d.getDate() > 1 ? d.getDate() - 1 : 1;
        const days_this_month = ( new Date(d.getFullYear(), d.getMonth()+1, 0) ).getDate();
        const forecast = raw * days_this_month / passed;

        bw.forecast = (forecast > 0) ? `当月転送量の予想: ${Number( (forecast / GiB).toPrecision(3) ).toLocaleString('ja')} GiB` : "";
        $scope.bandWidth = bw;

      } else {
        $scope.bandWidth = {
          bandwidth : "0 GiB",
          excess : "",
          forecast : "",
        }
      }
    })
  };

  const _freeBandwidthByPlan = function ( plan ) {
    const GiB = 1024 * 1024 * 1024;
    const TiB = GiB * 1024;
    let bandwidth;
    if ( plan.match( /^(S[12]|M3)/ ) ) {
      bandwidth = 1 * TiB;
    } else if ( plan.match( /^(S4|M4)/ ) ) {
      bandwidth = 2 * TiB;
    } else if ( plan.match( /^(M16|L8|L16|XL16)/ )) {
      bandwidth = 3 * TiB;
    }
    return bandwidth;
  }

  //////////////////////////////////////////////////////////////////////
  // Loading instance cpu usage data
  var _loadCpuUsage = function() {
    return Instances.getInstanceCpuUsage($routeParams.id).then(function(data){
      if ( data && data.labels ) {
        $scope.cpuMetricsLabels  = data.labels;
        $scope.cpuMetricsData  = data.data;
        $scope.cpuMetricsDatasetOverride = [{ yAxisID: 'y-axis-1' }];
        $scope.cpuMetricsOptions = {
          colors : [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
          title: {
            display: true,
            text: 'CPU 推移（％）',
            position: 'bottom'
          },
          scales: {
            yAxes: [
              {
              id: 'y-axis-1',
              ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100
                }
              }
            ]
          }
        };
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading instance memory usage data
  var _loadMemoryUsage = function() {
    return Instances.getInstanceMemoryUsage($routeParams.id).then(function(data){
      if ( data && data.labels ) {
        $scope.memoryMetricsLabels  = data.labels;
        $scope.memoryMetricsData  = data.data;
        $scope.memoryMetricsDatasetOverride = [{ yAxisID: 'y-axis-1' }];
        $scope.memoryMetricsOptions = {
          colors : [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
          title: {
            display: true,
            text: 'メモリ 推移（メガバイト）',
            position: 'bottom'
          },
          scales: {
            yAxes: [
              {
              id: 'y-axis-1',
              ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: data.size
                }
              }
            ]
          }
        };
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading author list from MT
  var _loadAuthors = function() {
    return Instances.listAuthors($routeParams.id).then(function(data){
      if ( data && data.length > 0 ) {
        $scope.authors = data;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading site list from MT
  var _loadSites = function() {
    return Instances.listSites($routeParams.id).then(function(data){
      $scope.sites = data;
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Checking to exist Inital Author in author list from MT
  var _checkAuthorsChanged = function() {
    var account = $scope.instance.instanceInitial.instanceInitialMtAdminAccount;
    $scope.isAuthorChanged = true;

    if ( typeof $scope.authors === "undefined" ) {
      $scope.isAuthorChanged = false;
    } else {
      for ( const a of $scope.authors ) {
        if ( a.author_name == account ) {
          $scope.isAuthorChanged = false;
          return;
        }
      }
    }
    return;
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: ''
  };

  $scope.editMode = false;
  $scope.action = null;
  $scope.canChangeFtpPassword = false;

  _load();
};

module.exports = instanceController;
