'use strict';

feedbackController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  'Feedback',
  'Instances',
  'Products'
];

function feedbackController($scope, $uibModal, $location, Feedback, Instances, Products) {
  $scope.contactPurposeList = [
    {value: 'Contract', label: '請求や契約についてのお問い合わせ'},
    {value: 'Migration', label: 'CentOS から Rocky Linux への移行に関するお問い合わせ'},
    {value: 'Technical Support', label: '技術的なお問い合わせ'},
    {value: 'Sandbox', label: 'サンドボックス作成サービスのお申し込み'},
    {value: 'Optimize Template', label: 'テンプレート最適化サービスのお申し込み'},
    {value: 'Cloudflare CDN Option', label: 'Cloudflare CDN/WAF オプションサービスのお申し込み'},
    {value: 'IDCF CDN Option', label: 'Fastly CDN オプションサービスのお申し込み'},
    {value: 'CSC WAF Option', label: '攻撃遮断くん WAF オプションサービスのお申し込み'},
    {value: 'Anti Virus Option', label: 'ウイルス検知オプションサービスのお申し込み' },
    {value: 'Change Plan', label: 'プラン変更のお申し込み'},
    {value: 'Security Check', label: 'セキュリティ及びネットワーク診断の申請'},
    {value: 'Moving Site', label: 'サイト移行サービスのお申し込み'},
    {value: 'Cancellation', label: '解約のご依頼・お問い合わせ'},
    {value: 'Miscellaneous', label: 'その他のお問い合わせ'}
  ];
  $scope.contactPurpose = null;

  $scope.serviceList = [
    {id: 'sandbox-service', value: 'サンドボックス作成サービス', label: 'サンドボックス作成サービス'},
    {id: 'optimize-template', value: 'テンプレート最適化サービス', label: 'テンプレート最適化サービス'}
  ];

  $scope.sandboxDomainMapped = {
    value: "いいえ"
  };

  $scope.sandboxSwitchProduction = {
    value: "いいえ"
  };

  $scope.holidays = [
    "2023-01-01",
    "2023-01-02",
    "2023-01-03",
    "2023-01-09",
    "2023-02-11",
    "2023-02-23",
    "2023-03-21",
    "2023-04-29",
    "2023-05-03",
    "2023-05-04",
    "2023-05-05",
    "2023-07-17",
    "2023-08-11",
    "2023-08-13",
    "2023-08-14",
    "2023-08-15",
    "2023-08-16",
    "2023-09-18",
    "2023-09-20",
    "2023-09-23",
    "2023-10-09",
    "2023-11-03",
    "2023-11-23",
    "2023-12-29",
    "2023-12-30",
    "2023-12-31",
    "2024-01-01",
    "2024-01-02",
    "2024-01-03",
    "2024-01-08",
    "2024-02-11",
    "2024-02-12",
    "2024-02-23",
    "2024-03-20",
    "2024-04-29",
    "2024-04-30",
    "2024-05-01",
    "2024-05-02",
    "2024-05-03",
    "2024-05-04",
    "2024-05-05",
    "2024-05-06",
    "2024-07-15",
    "2024-08-11",
    "2024-08-12",
    "2024-09-16",
    "2024-09-22",
    "2024-09-23",
    "2024-10-14",
    "2024-11-03",
    "2024-11-04",
    "2024-11-23",
    "2024-12-29",
    "2024-12-30",
    "2024-12-31",
  ];

  $scope.sortOrder = {
    'plan': [ 'S1', 'S2', 'S4', 'M4', 'M8', 'M16', 'L8', 'L16', 'XL16' ],
    'storage': [ '', '50', '100', '300', '500' ],
    'type': [ '7', 'a7', '8', 'a8', '7P', 'a7P', '8P', 'a8P' ]
  };

  var dateDisabled = function(r) {
    var mode = r.mode; var date = r.date;
    if ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) ) {
      return true;
    }

    // start 1 week later
    var available = new Date();
    available = available.setDate( available.getDate() + 6 );
    if ( date < available ) {
      return true
    }

    var dd = new Date( date.getTime() - date.getTimezoneOffset() * 60000 );
    var d = dd.toISOString().slice(0,10);
    return $scope.holidays.includes(d);
  };

  $scope.dateOptions = {
    formatYear: 'yy',
    minDate: new Date(),
    startingDay: 0,
    showWeeks: false,
    dateDisabled: dateDisabled
  };

  // Disable weekend and holiday
  var upgradeDisabled = function(r) {
    var mode = r.mode; var date = r.date;
    if ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 3 || date.getDay() === 6 ) ) {
      return true;
    }
    var dd = new Date( date.getTime() - date.getTimezoneOffset() * 60000 );
    var d = dd.toISOString().slice(0,10);
    return $scope.holidays.includes(d);
  };

  $scope.dateOptionsUpgrade = {
    datepickerMode: "day",
    formatYear: 'yy',
    startingDay: 0,
    showWeeks: false,
    formatDayTitle: "yyyy年M月",
    formatMonth: "M月",
    formatDay: "d",
    dateDisabled: upgradeDisabled
  };

  var today = new Date();
  var upgradeStartDate = new Date( 2021, 5 - 1, 13 );
  $scope.dateOptionsUpgrade.minDate = ( today > upgradeStartDate ) ? today : upgradeStartDate;
  $scope.dateOptionsUpgrade.initDate = ( today > upgradeStartDate ) ? today : upgradeStartDate;

  $scope.datePicker = {
    opened: false
  };

  $scope.isInstanceLoaded = false;

  var isAvailableAntiVirusOption = function( instance ) {
    if ( instance.instanceDetail.instanceDetailPlan.match( /^(M8|M16|L8|L16|XL16)/ ) && instance.instanceDetail.instanceDetailProductVersion == 8 ) {
      return true;
    }
    return false;
  };

  Instances.listInstances().then(
    function(result){
      if (angular.isArray(result)) {
        var instances = [];
        var instancesForAV = [];
        if ( result.length > 0 ) {
          angular.forEach(result, function(instance, i){
            if ( instance.instanceStatus == 1 ) {
              if ( isAvailableAntiVirusOption( instance ) ) {
                instance.av_option = true;
              } else {
                instance.av_option = false;
              }
              instances.push(instance);
              $scope.isInstanceLoaded = true;
            }
          });
          if ( instances.length > 0) {
            $scope.noInstances = false;
            $scope.instanceList = instances;
          }
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    },
    function (result) {
      $scope.messages.status = 'danger';
      $scope.messages.message = result;
    }
  );


  $scope.messages = {
    status: '',
    message: ''
  };

  var _loadPlan = function(index) {
    var instance = $scope.instanceList[index];
    var orderId;
    if (angular.isObject(instance.order)) {
      orderId = instance.order.orderId;
    }
    else {
      orderId = instance.order;
    }

    Products.listProducts(orderId).then(
      function (result) {
        if (angular.isArray(result)) {
          $scope.plans = [];

          const productNames = [];
          for ( const p of result ) {
            productNames.push( p.productName );
          }

          for ( const p of $scope.sortOrder['plan'] ) {
            for ( const s of $scope.sortOrder['storage'] ) {
              for ( const t of $scope.sortOrder['type'] ) {
                const name = `${p}${s}i${t}`;
                if ( productNames.includes( name ) ) {
                  const desc = `CPU: ${p}, ストレージ: ${s ? s : 10} GB, ウェブサーバ: ${t.substr(0,1) == 'a' ? 'Apache httpd' : 'nginx' }`;
                  $scope.plans.push( { 'name': name, 'description': desc } );
                }
              }
            }
          }

          $scope.planList = result;
          $scope.newPlan = null;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function (result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  var _sendFeedback = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    // Reset freetext input when user not selected freetext
    var data = {};
    data.purpose = $scope.contactPurpose;
    data.instanceId = $scope.targetInstance;
    data.body = $scope.feedbackBody;
    data.billingZipCode = $scope.billingZipCode;
    data.billingAddress = $scope.billingAddoress;
    data.billingTel = $scope.billingTel;
    data.billingCompany = $scope.billingCompany;
    data.billingDept = $scope.billingDivision;
    data.billingPerson = $scope.billingPerson;
    data.sourceUrl = $scope.transferFromUrl;

    if ( $scope.newPlanDate ) {
      var yyyy = $scope.newPlanDate.getFullYear();
      var mm = ('0' + ($scope.newPlanDate.getMonth() + 1)).slice(-2);
      var dd = ('0' + ($scope.newPlanDate.getDate())).slice(-2)
      data.desiredDate = yyyy + '年' + mm + '月' + dd + '日';
    }

    var options = '';
    $scope.serviceList.some(function(val) {
      if ( val.checked ) {
        if ( options )
          options(', ');
        options += val.value
        return true;
      }
      return false;
    });
    data.optionService = options;

    if ( $scope.newPlan ) {
        const newProduct = $scope.planList.filter( p => p.productName === $scope.newPlan );
        data.newProductId = newProduct[0].productId
        $scope.newPlanName = newProduct[0].productName;
    }

    if ( $scope.instanceList != null ) {
      $scope.instanceList.some(function(val){
        if ( val.instanceId == $scope.targetInstance) {
          $scope.targetInstanceName = val.instanceHostname;
          return true;
        }
        return false;
      });
    }
    $scope.contactPurposeList.some(function(val){
      if ( val.value == $scope.contactPurpose) {
        $scope.contactPurposeLabel = val.label;
        return true;
      }
      return false;
    });

    if ( $scope.contactPurpose == "Upgrade" ) {
      if ( $scope.upgradeDate ) {
        var yyyy = $scope.upgradeDate.getFullYear();
        var mm = ('0' + ($scope.upgradeDate.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.upgradeDate.getDate())).slice(-2)
        data.upgradeDate = yyyy + '年' + mm + '月' + dd + '日';
      }
    }

    if ( $scope.contactPurpose == "Sandbox") {
      data.sandboxSwitchProduction = $scope.sandboxSwitchProduction.value;
      data.sandboxDomainMapped = $scope.sandboxDomainMapped.value;
    }

    if ( $scope.contactPurpose == "Security Check") {
      data.dateOfTest = $scope.dateOfTest;
      data.sourceIp = $scope.sourceIp;
      data.focusedMatter = $scope.focusedMatter;
      data.assumedTraffic = $scope.assumedTraffic;
      data.checkMethod = $scope.checkMethod;
      data.emergencyContact = $scope.emergencyContact;
    }

    Feedback.sendCustomerFeedback(data).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'お問い合わせを受け付けました。';
        $scope.isSent = true;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
        $scope.isSent = false;
      }
    });
  };

  var _backToDashbioard = function() {
    $location.path('/dashboard');
  };

  var _openDatePicker = function() {
    $scope.datePicker.opened = true;
  };

  $scope.sendFeedback = _sendFeedback;
  $scope.loadPlan = _loadPlan;
  $scope.back = _backToDashbioard;
  $scope.openDatePicker = _openDatePicker;
}

module.exports = feedbackController;
