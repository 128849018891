'use strict';

global.jQuery = require('jquery');

var angular = require('angular');

require('angular-animate');
require('angular-jwt');
require('angular-resource');
require('angular-route');
require('angular-sanitize');
require('angular-ui-bootstrap');
require('angular-chart.js');
require('chart.js');
require('ngstorage');
require('bootstrap');


var app = angular.module('app', ['ngRoute','ngResource', 'ngStorage', 'ui.bootstrap', 'ngSanitize', 'chart.js']);

if ( !process.env.NODE_ENV ) {
  // Default profiile is development
  process.env.NODE_ENV = "development";
}

if (process.env.NODE_ENV === "development") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return location.protocol + "//" + location.hostname + ':8080';
    }
  });
}
if (process.env.NODE_ENV === "stage") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return 'https://stage.mypage.movabletype.cloud';
    }
  });
}
if (process.env.NODE_ENV === "production") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return "https://mypage.movabletype.cloud";
    }
  });
}

if (process.env.NODE_ENV !== "development") {
  app.factory('$exceptionHandler', function ($log) {
    if (typeof airbrakeJS !== "undefined") {
      var airbrake = new airbrakeJs.Client({
        projectId: 109229,
        projectKey: 'a5df6f96ffeba38bb92926035028031c'
      });

      airbrake.addFilter(function (notice) {
        notice.context.environment = process.env.NODE_ENV;
        return notice;
      });

      return function (exception, cause) {
        $log.error(exception);
        airbrake.notify({error: exception, params: {angular_cause: cause}});
      };
    }
    return function (exception, cause) {
      $log.error(exception);
    };
  });
}

require('./controllers');
require('./models');
require('./services');

app.config(['$routeProvider', '$httpProvider', '$locationProvider', function($routeProvider, $httpProvider, $locationProvider) {
  $locationProvider.hashPrefix('');

  $routeProvider
    .when('/signin',{
      templateUrl: '/views/signin.html',
      controller: 'authController'
    })
    .when('/dashboard',{
      templateUrl: '/views/dashboard.html',
      controller: 'dashboardController'
    })
    .when('/instances/:id', {
      templateUrl: '/views/instance.html',
      controller: 'instanceController'
    })
    .when('/instances/:id/logs', {
      templateUrl: '/views/server_log.html',
      controller: 'serverLogController'
    })
    .when('/signout',{
      redirectTo: '/signin'
    })
    .when('/users/:id', {
      templateUrl: '/views/user.html',
      controller: 'userController'
    })
    .when('/feedback/new', {
      templateUrl: '/views/contact.html',
      controller: 'feedbackController'
    })
    .when('/forgot',{
      templateUrl: '/views/forgot_password.html',
      controller: 'authController'
    })
    .when('/forgot_request_done', {
      templateUrl: '/views/forgot_request_done.html',
      controller: 'authController'
    })
    .when('/reset_password', {
      templateUrl: '/views/new_password.html',
      controller: 'authController'
    })
    .when('/password_reset_done', {
      templateUrl: '/views/password_reset_done.html',
      controller: 'authController'
    })
    .when('/manual', {
      templateUrl: '/views/manual/index.html',
      controller: 'manualController',
      reloadOnSearch: false
    })
    .otherwise(
      { redirectTo: '/signin'}
  );

  $httpProvider.interceptors.push([
    '$q',
    '$sessionStorage',
    '$location',
    '$injector',
    '$window',
    'API',
    function($q, $sessionStorage, $location, $injector, $window, API) {
      return {
        'request': function(config) {
          var url = config.url;
          var regex = new RegExp('^' + API + '/api/')
          if (url.match(regex)) {
            if ($sessionStorage.token) {
              config.headers['Authorization'] = "Bearer " + $sessionStorage.token;
            } else {
              $location.redirect('/signin');
            }
          }
          return config;
        },
        'responseError': function(response) {
          if (response.status===401) {
            var deferred = $q.defer();
            var url = API + '/login';
            var regex = new RegExp('^' + url);
            if (response.config.url.match(regex)) {
              deferred.reject(response);
            } else {
              var param = {
                username: $sessionStorage.username,
                password: $sessionStorage.password
              };
              $injector.get('$http')({
                method: 'post',
                url: url,
                data: param
              }).then( function onSuccess(res){
                var token = res.data.token;
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var parsed = JSON.parse($window.atob(base64));

                $sessionStorage.token = token;
                $sessionStorage.tokenParsed = parsed;
                $sessionStorage.isAuthed = true;

                $injector.get("$http")(response.config).then(function(resp) {
                  deferred.resolve(resp);
                }, function(resp) {
                  deferred.reject();
                });
              }, function onError(data, status, headers, config){
                deferred.reject();
                $location.path('/signin');
                return;
              });
            }
            return deferred.promise;
          }
          return $q.reject(response);
        }
      };
    }
  ]);
}]);

app.run([
  '$rootScope',
  '$location',
  '$route',
  '$sessionStorage',
  function($rootScope, $location, $route, $sessionStorage){
    $rootScope.$on('$routeChangeStart', function(ev, next, current){
      if (next.controller == 'authController') {
        if (typeof current !== "undefined" && current.originalPath != '/signout') {
          if ($sessionStorage.token) {
            $location.path('/dashboard');
            $route.reload();
          }
        }
      }
      else if (next.controller == 'manualController') {
      }
      else {
        if (!$sessionStorage.token) {
          $location.path('/signin');
          $route.reload();
        }
      }
    });
  }
]);
