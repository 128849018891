'use strict';

Instances.$inject = [
  '$rootScope',
  '$http',
  'API'
]

function Instances( $rootScope, $http, API ) {
  var _listInstances = function() {
    var url = API + '/api/instances/';
    return $http({
      method: 'get',
      url: url,
    });
  };

  var _getInstance = function(id) {
    var url = API + '/api/instances/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _addComment = function(id, params) {
    var url = API + '/api/instances/' + id + '/comment';
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _getInstanceDiskUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/disk';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstanceCpuUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/cpu';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstanceMemoryUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/memory';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getServerLog = function(id, type) {
    var url = API + '/api/instances/' + id + '/log';
    return $http({
      method: 'get',
      url: url,
      params: type
    });
  }

  var _resetFtpPassword = function(id, param) {
    var url = API + '/api/instances/' + id + '/ftp/reset';
    return $http({
      method: 'post',
      url: url,
      data: param
    });
  };

  var _getNetworkBandWidth = function(id, param) {
    var url = API + '/api/instances/' + id + '/network';
    return $http({
      method: 'get',
      url: url,
      params: param
    });
  };

  var _restartMT = function(id) {
    var url = API + '/api/instances/' + id + '/mt/restart';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _listAuthors = function(id) {
    var url = API + '/api/instances/' + id + '/mt/authors';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listSites = function(id) {
    var url = API + '/api/instances/' + id + '/mt/sites';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _restoreStatus = function(id) {
    var url = API + '/api/instances/' + id + '/status/restore';
    return $http({
      method: 'post',
      url: url,
    });
  };

  var _upgradeMT8 = function(id) {
    var url = API + '/api/instances/' + id + '/upgradeMT';
    return $http({
      method: 'post',
      url: url,
    });
  };

  var _rollback = function(id, params) {
    var url = API + '/api/instances/' + id + '/rollback';
    return $http({
      method: 'post',
      url: url,
      data: params,
    });
  };

  var _getBackupFiles = function(id) {
    var url = API + '/api/instances/' + id + '/backup';
    return $http({
      method: 'post',
      url: url,
    });
  };

  return {
    listInstances: function() {
      return _listInstances().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstance: function(id) {
      return _getInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    addComment: function(id, params) {
      return _addComment(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceDiskUsage: function(id) {
      return _getInstanceDiskUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceCpuUsage: function(id) {
      return _getInstanceCpuUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceMemoryUsage: function(id) {
      return _getInstanceMemoryUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getServerLog: function(id, type) {
      return _getServerLog(id, type).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    resetFtpPassword: function(id, param) {
      return _resetFtpPassword(id, param).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getNetworkBandWidth: function(id, param) {
      return _getNetworkBandWidth(id, param).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    restartMT: function(id) {
      return _restartMT(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listAuthors: function(id) {
      return _listAuthors(id).then(
        function(res){
          return res.data;
        },
        function(res){
          if (res) {
            return res.data;
          } else {
            return null;
          }
        }
      );
    },
    listSites: function(id) {
      return _listSites(id).then(
        function(res){
          return res.data;
        },
        function(res){
          if (res) {
            return res.data;
          } else {
            return null;
          }
        }
      );
    },
    restoreStatus: function(id) {
      return _restoreStatus(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    rollback: function(id, date) {
      console.log( params );
      var params = { date: date };
      return _rollback(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    upgradeMT8: function(id) {
      return _upgradeMT8(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getBackupFiles: function(id) {
      return _getBackupFiles(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
  };
};

module.exports = Instances;
